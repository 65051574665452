import React from "react";
import { Button, Modal } from "react-bootstrap"


class ConfirmDialog extends React.Component {
  constructor(props)
  {
      super(props);
      this.handleHide = this.handleHide.bind(this);
      this.callback = null
      this.state =
      {
          title:"",
          message:"",
          show: false
      };
  }

  displayConfirmBox(title, message, callback){
    this.callback = callback
    this.setState(
      {
        title:title,
        message:message,
        show: true
      })
  }

  handleHide(answer) {
    this.setState(
      {
        title:"",
        message:"",
        show: false
      })
      if (this.callback){
        this.callback(answer)
      }
    }

  show(bShow){
    this.setState({show:bShow})
  }
  render()
  {
      return (
        <div className="static-modal">
        <Modal
          {...this.props}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-lg">
              {this.state.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
            {this.state.message}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => {this.handleHide(false)}}>No</Button>
            <Button bsStyle="primary" onClick={() => {this.handleHide(true)}}>Yes</Button>
          </Modal.Footer>
        </Modal>
        </div>
      );
    }

}
export default ConfirmDialog;

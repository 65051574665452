import React from "react";

class PageHeader extends React.Component
{
  render()
  {
    return (
      <div className="PageHeader" style={{width:'100%', flexDirection: 'column', display: 'flex'}}>
        <h3>{this.props.title}</h3>
        <hr style={{marginTop:'0px', marginBottom:'10px'}}/>
        <p style={{marginBottom:'20px'}}>{this.props.info}</p>
      </div>
    );
  }
}

export default PageHeader;

import React from "react";
import { Modal } from "react-bootstrap"


class LoadingDialog extends React.Component {
  constructor(props)
  {
      super(props);
      this.state =
      {
          show: false
      };
  }

  show(bShow){
    this.setState({show:bShow})
  }
  render()
  {
      return (
        <div className="static-modal">
        <Modal
          {...this.props}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="custom-modal"
        >
          <Modal.Body>
            <h4>Loading...</h4>
          </Modal.Body>
        </Modal>
        </div>
      );
    }

}
export default LoadingDialog;

import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function AuthenticatedRoute({ component: C, appProps, ...rest })
{
  return (
    <Route {...rest} render={props => appProps.isAuthenticated && !isNaN(sessionStorage['last_activity_time']) ? <C {...props} {...appProps} /> :
          <Redirect to={{ pathname: "/loggedout" }} />
 } />
  );
}

import React from 'react';
import { Auth } from 'aws-amplify';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import './ChangePassword.css';
import { Button } from 'react-bootstrap';
import ErrorDialog from './ErrorDialog';
import PageHeader from '../components/PageHeader';
import { API } from 'aws-amplify';
import config from '../config';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange_password = this.handleChange_password.bind(this);
    this.handleChange_confirm_password = this.handleChange_confirm_password.bind(this);

    this.state = {
      password: '',
      confirm_password: '',
    };
  }

  async componentDidMount() {}

  handleChange_password(e) {
    this.setState({ password: e.target.value });
  }

  handleChange_confirm_password(e) {
    this.setState({ confirm_password: e.target.value });
  }

  isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  getQBFiles() {
    //    alert(config.environment.APP_PREFIX);
    return API.get('cloudautocheckissues', '/getQBFileList', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
        user_id: this.props.location.state.userName,
      },
    });
  }

  async audit(dataSuccess, dataFunction, dataDescription) {
    try {
      await API.post('cloudautocheckissues', '/sessionData', {
        body: {
          success: dataSuccess,
          function: dataFunction,
          desc: dataDescription,
        },
      });
    }
    catch (e)
    {
      console.log("Error creating audit record: " + e.message);
    }
  }

  async onClick_ForgotPasswordConfirm(event) {
    try {
      if (this.isBlank(this.state.password)) {
        this.refs.errorDialog.displayError('Password', 'Password must be entered');
        //errorDialog("Password", "Password must be entered")
      } else if (this.isBlank(this.state.confirm_password)) {
        this.refs.errorDialog.displayError('Confirm Password', 'Confirm Password must be entered');
        //errorDialog("Confirm Password", "Confirm Password must be entered")
      } else if (this.state.password !== this.state.confirm_password) {
        this.refs.errorDialog.displayError(
          'Password Mismatch',
          'Password and Confirm Password must match'
        );
        //errorDialog("Password Mismatch", "Password and Confirm Password must match")
      } else {
        //        var un = this.props.location.state.username;
        //        var op = this.props.location.state.oldpassword;
        //        var p = this.state.password;

        Auth.signIn(this.props.location.state.userName, this.props.location.state.oldPassword)
          .then((user) => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
//              const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
              Auth.completeNewPassword(
                user, // the Cognito User Object
                this.state.password // the new password
                // OPTIONAL, the required attributes
                //                    {
                //                      email: 'xxxx@example.com',
                //                      phone_number: '1234567890'
                //                    }
              )
                .then(async (user) => {
                  // at this time the user is logged in if no MFA required
                  await Auth.signOut();
                  alert("Your password has been changed. You must login again with your new password.")
                  sessionStorage['last_activity_time'] = "";
                  this.props.setIsAuthenticated(false);
                  this.props.history.push('/login');
                  /*
                  console.log(user);
                  this.props.setIsAuthenticated(true);
                  sessionStorage['last_activity_time'] = new Date().getTime();
                  var data = await this.getQBFiles();
                  if (data.files.length > 0) {
                    this.props.history.push('/upcomingdeliveries');
                  } else {
                    this.props.history.push('/connectaccountingsystem');
                  }
                  */
                })
                .catch((e) => {
                  console.log(e);
                  this.refs.errorDialog.displayError('Error', e.message);
                });
            } else {
              // other situations
            }
            this.audit(true, "Change Password", "Success");
          })
          .catch((e) => {
            console.log(e);
            this.audit(false, "Change Password", "Failure");
            this.refs.errorDialog.displayError('Error', e.message);
          });
        /*
        var u = await Auth.signIn(props.location.state.userName, props.location.state.oldPassword);
        var user = Auth.currentAuthenticatedUser();
        var changePasswordData = await Auth.completeNewPassword(user, this.state.password);
        
//        var changePasswordData = await Auth.changePassword(
//          user,
//          this.props.location.state.oldpassword,
//          this.state.password
//        );

        this.props.setIsAuthenticated(true);
        sessionStorage['last_activity_time'] = new Date().getTime();
        var data = await this.getQBFiles();
        if (data.files.length > 0) {
          this.props.history.push('/upcomingdeliveries');
        } else {
          this.props.history.push('/connectaccountingsystem');
        }
*/
        /*        
        await Auth.forgotPasswordSubmit(
          this.props.location.state.username,
          this.state.confirmation_code,
          this.state.password
        );
        this.props.history.push('/login');
*/
      }
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
      //errorDialog("Error", e.message)
    } finally {
    }
  }

  render() {
    return (
      <div className='ChangePassword'>
        <PageHeader title='Change Password' info='' />
        <div style={{ padding: '30px' }} className='widget'>
          <form name='settingsForm' autocomplete='off'>
            {/*<FormGroup style={{marginBottom:'30px'}} controlId="confirmationccode" bsSize="large">
                <ControlLabel>Verification Code:</ControlLabel>
                <FormControl type="text" placeholder=""  onChange={this.handleChange_confirmation_code}/>
    </FormGroup>*/}
            <FormGroup style={{ marginBottom: '30px' }} controlId='password' bsSize='large'>
              <ControlLabel>Password:</ControlLabel>
              <FormControl type='password' placeholder='' onChange={this.handleChange_password} />
            </FormGroup>
            <FormGroup style={{ marginBottom: '30px' }} controlId='confirmpassword' bsSize='large'>
              <ControlLabel>Confirm Password:</ControlLabel>
              <FormControl
                type='password'
                placeholder=''
                onChange={this.handleChange_confirm_password}
              />
            </FormGroup>
            <Button
              style={{ marginTop: '20px', width: '100%' }}
              bsSize='large'
              onClick={() => {
                this.onClick_ForgotPasswordConfirm();
              }}
              alt='...'
            >
              Submit
            </Button>
          </form>
        </div>
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
}

export default ChangePassword;

import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import './App.css';
import Routes from './Routes';
import './css/branding.css';
import Clock from './components/Clock';
import config from './config';
import { Auth } from 'aws-amplify';

function App(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    console.log('App.js onLoad()');
    if (config.bank.SSO === 'true') {
      setIsAuthenticated(true);
      setIsAuthenticating(false);
    } else {
      try {
        await Auth.currentSession();
        setIsAuthenticated(true);
      } catch (e) {
        if (e !== 'No current user') {
          alert(e);
        }
      }

      setIsAuthenticating(false);
    }
  }

  async function handleLogout() {

    var exit = window.confirm("Are you sure you want to exit?");

    if (exit)
    {
      await Auth.signOut();

      setIsAuthenticated(false);

      sessionStorage['last_activity_time'] = '';

      props.history.push('/login');
    }
  }

  return (
    <div>
      {config.bank.SSO === 'true' ? (
        <div className='App container'>
          <Navbar style={{ backgroundColor: '#ffffff' }} collapseOnSelect fixedTop>
            <Navbar.Header>
              <Navbar.Brand>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    style={{ height: '30px', marginRight: '15px' }}
                    src={process.env.PUBLIC_URL + '/logo_banner.png'}
                    alt='Company Logo'
                  />
                  <span className='branded-text' style={{ whiteSpace: 'nowrap' }} to='/'>
                    {config.bank.PRODUCT_NAME}
                  </span>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle style={{ float: 'right' }} />
            </Navbar.Header>
          </Navbar>
          <Clock />
          <div style={{ marginTop: '50px' }} id='page_container'>
            <Routes appProps={{ isAuthenticated, setIsAuthenticated }} />
          </div>
        </div>
      ) : (
        <div className='App container'>
          <Navbar style={{ backgroundColor: '#ffffff' }} collapseOnSelect fixedTop>
            <Navbar.Header>
              <Navbar.Brand>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    style={{ height: '30px', marginRight: '15px' }}
                    src={process.env.PUBLIC_URL + '/logo_banner.png'}
                    alt='Company Logo'
                  />
                  <span className='branded-text' style={{ whiteSpace: 'nowrap' }} to='/'>
                    {config.bank.PRODUCT_NAME}
                  </span>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle style={{ float: 'right' }} />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav pullRight>
                {isAuthenticated && !isNaN(sessionStorage['last_activity_time']) ? (
                  <NavItem onClick={handleLogout}>Logout</NavItem>
                ) : (
                  <>
                    {/*<LinkContainer to='/signup'>
                      <NavItem>Signup</NavItem>
                </LinkContainer>*/}
                    <LinkContainer to='/login'>
                      <NavItem>Login</NavItem>
                    </LinkContainer>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Clock />
          <div style={{ marginTop: '50px' }} id='page_container'>
            <Routes appProps={{ isAuthenticated, setIsAuthenticated }} />
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(App);

import React from 'react';
import { Button } from 'react-bootstrap';
import { API } from 'aws-amplify';

class ConnectToSageButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { sageUrl: '' };
  }

  async componentDidMount() {
    var response = await this.getQuickbooksInfo();
    this.setState({ sageUrl: response.url });
    console.log('url = ', response.url);
  }

  handleClick = (e) => {
    window.open(this.state.sageUrl, '_self');
    //    window.open('http://www.google.com', '_self');
    e.preventDefault();
  };

  getQuickbooksInfo() {
    console.log('API getOAuthURL');
    return API.get('cloudautocheckissues', '/getOAuthURL', {
      queryStringParameters: {
        service: 'SAGE',
        //        app_prefix: config.environment.APP_PREFIX,
      },
    });
  }

  render() {
    return (
      <Button
        style={{ marginTop: '40px', height: '36px', background: '#00DC00', color: 'white' }}
        //        className={`LoaderButton ${className}`}
        //        disabled={disabled || isLoading}
        //        {...props}
        onClick={this.handleClick}
      >
        Connect
      </Button>
    );
  }
}

export default ConnectToSageButton;

/*
import React from "react";
import { Button } from "react-bootstrap";

export default function ConnectToSageButton({
  isLoading,
  className = "",
  disabled = false,
  ...props
}) {
  return (
    <Button style={{marginTop: '40px', height:'40px', background:'#00DC00', color:'white'}}
      className={`LoaderButton ${className}`}
      disabled={disabled || isLoading}
      {...props}
    >
Connect
    </Button>
  );
}
*/

import './ConnectToQuickbooksResult.css';
import React from "react";
import { Grid, Row, Col, Button } from "react-bootstrap"
import { API } from "aws-amplify";
import config from "../config"
import LoadingDialog from "./LoadingDialog"
import TimeoutComponent from "../components/TimeoutComponent"

class ConnectToQuickbooksResult extends TimeoutComponent
{
  constructor(props)
  {
    super(props);

    this.onClick_ContinueButton = this.onClick_ContinueButton.bind(this)
    this.exchangeToken = this.exchangeToken.bind(this);

    this.error = props.location.quickbooks_props.error;
    this.realmId = props.location.quickbooks_props.realmId;
    this.code = props.location.quickbooks_props.code;

    this.state = { isLoaded: false, file: {sequence_num: '', company_name: ''} };
  }

  async componentDidMount()
  {
    this.refs.loadingDialog.show(true)
    try
    {
      if (!this.error)
      {
        var result = await this.exchangeToken();
        if (!result.error)
        {
          this.setState({'file': {'sequence_num': result.sequence_num, 'company_name': result.company_name}});
        }
        else
        {
          this.error = result.error;
          this.forceUpdate();
        }
      }
    }
    catch (e)
    {
      this.error = e.message;
      this.forceUpdate();
    }
    finally
    {
      this.state.isLoaded = true
      this.setState({isloaded: true})

      this.refs.loadingDialog.show(false)
    }
  }

  exchangeToken()
  {
    console.log("API exchangeToken");
    return API.post("cloudautocheckissues", "/exchangeToken", {
      body:
      {
        "app_prefix": config.environment.APP_PREFIX,
        "accessCode": this.code,
        "realmId": this.realmId
      }
    });
  }

  onClick_ContinueButton()
  {
    if (!this.error)
    {
      this.props.history.push({pathname: "/bankaccountsetup", state: { "file": this.state.file,"from":"connecttoquickbooksresult"} });
    }
    else
    {
      this.props.history.push({pathname: "/connectaccountingsystem"});
    }
  }

  render()
  {
    if (this.state.isLoaded)
    {
      if (!this.error)
      {
        return (
          <div>
            <Grid className="ConnectToQuickbooksResult" style={{marginTop:'100px', width:'100%'}}  fluid={true}>
              <Row>
                <Col xs={12} style={{flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
                  <h4 style={{color:'#0c6aae', margin:'25px'}}>SUCCESS</h4>
                  <img style={{height: '100px'}} src={process.env.PUBLIC_URL + '/success.png'} alt="..."/>
                  <div id='message_success'> <p style={{margin:'25px'}}>You have successfully connected to {this.state.file.company_name}.</p> </div>
                  <Button style={{height:'40px', background:'#0c6aae', color:'white'}} onClick={() => {this.onClick_ContinueButton()}}>Continue
                  </Button>
                </Col>
              </Row>
            </Grid>
            <LoadingDialog ref="loadingDialog"/>
          </div>
        );
      }
      else
      {
        return (
          <div>
            <Grid className="ConnectToQuickbooksResult" style={{marginTop:'100px', width:'100%'}}  fluid={true}>
              <Row>
                <Col xs={12} style={{flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
                  <h4 style={{color:'red', margin:'25px'}}>FAILURE</h4>
                  <img style={{height: '100px'}} src={process.env.PUBLIC_URL + '/failure.png'} alt="..."/>
                  <div id='message_failure'><p style={{margin:'25px'}}> {this.error} </p> </div>
                  <Button style={{height:'40px', background:'#0c6aae', color:'white'}} onClick={() => this.props.history.push('/connectaccountingsystem')}>Continue
                  </Button>
                </Col>
              </Row>
            </Grid>
            <LoadingDialog ref="loadingDialog"/>
          </div>
        );
      }
    }
    else
    {
      return (
        <div>
          <LoadingDialog ref="loadingDialog"/>
        </div>
      );
    }
  }
}

export default ConnectToQuickbooksResult;

import React from 'react';
import { Button } from 'react-bootstrap';
import './PrivacyPolicy.css';
import ErrorDialog from './ErrorDialog';
import PageHeader from '../components/PageHeader';
import PrivacyPolicyText from '../components/PrivacyPolicyText';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick_CloseButton = this.handleClick_CloseButton.bind(this);
  }

  async componentDidMount() {}

  async handleClick_CloseButton() {
    try {
      this.props.history.push('/login');
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    }
  }
   
  render() {
    return (
      <div className='Eula'>
        <PageHeader title='Privacy Policy' info='' />
        <div style={{ padding: '30px' }} className='widget'>
          <form name='privacyPolicyForm' autocomplete='off'>
            <PrivacyPolicyText/>

            <Button
              style={{ marginTop: '20px' }}
              bsSize='large'
              onClick={() => {
                this.handleClick_CloseButton();
              }}
              alt='...'
            >
              Close
            </Button>
          </form>
        </div>
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
}

export default PrivacyPolicy;

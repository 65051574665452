//import Signup from './containers/Signup';
import Login from './containers/Login';
import ChangePassword from './containers/ChangePassword';
import ForgotPassword from './containers/ForgotPassword';
import ForgotPasswordConfirm from './containers/ForgotPasswordConfirm';

import config from './config';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import LoggedOut from './containers/LoggedOut';
import ConnectAccountingSystem from './containers/ConnectAccountingSystem';
import ConnectToQuickbooksResult from './containers/ConnectToQuickbooksResult';
import ConnectToSageResult from './containers/ConnectToSageResult';
import BankAccountSetup from './containers/BankAccountSetup';
import UpcomingDeliveries from './containers/UpcomingDeliveries';
import RealmSettings from './containers/RealmSettings';
import FileHistory from './containers/FileHistory';
import GetCheckData from './containers/GetCheckData';
import SageCompanyFileList from './containers/SageCompanyFileList';
import Eula from './containers/Eula';
import PrivacyPolicy from './containers/PrivacyPolicy';

import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

export default function Routes({ appProps }) {
  if (config.bank.SSO === 'true') {
    return (
      <Switch>
        <UnauthenticatedRoute path='/' exact component={Home} appProps={appProps} />
        <AuthenticatedRoute
          path='/upcomingdeliveries'
          exact
          component={UpcomingDeliveries}
          appProps={appProps}
        />
        <UnauthenticatedRoute
          path='/privacypolicy'
          exact
          component={PrivacyPolicy}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/connectaccountingsystem'
          exact
          component={ConnectAccountingSystem}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/connecttoquickbooksresult'
          exact
          component={ConnectToQuickbooksResult}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/connecttosageresult'
          exact
          component={ConnectToSageResult}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/bankaccountsetup'
          exact
          component={BankAccountSetup}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/realmsettings'
          exact
          component={RealmSettings}
          appProps={appProps}
        />
        <AuthenticatedRoute path='/filehistory' exact component={FileHistory} appProps={appProps} />
        <AuthenticatedRoute
          path='/getcheckdata'
          exact
          component={GetCheckData}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/sagecompanyfilelist'
          exact
          component={SageCompanyFileList}
          appProps={appProps}
        />
        <UnauthenticatedRoute path='/loggedout' exact component={LoggedOut} appProps={appProps} />
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
        {/*
      <UnauthenticatedRoute path="/*" component={Login} appProps={appProps} />
*/}
      </Switch>
    );
  } else {
    return (
      <Switch>
        <UnauthenticatedRoute path='/' exact component={Home} appProps={appProps} />
        {/*<UnauthenticatedRoute path='/signup' exact component={Signup} appProps={appProps} />*/}
        <UnauthenticatedRoute path='/login' exact component={Login} appProps={appProps} />
        <AuthenticatedRoute
          path='/eula'
          exact
          component={Eula}
          appProps={appProps}
        />
        <UnauthenticatedRoute
          path='/privacypolicy'
          exact
          component={PrivacyPolicy}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/changepassword'
          exact
          component={ChangePassword}
          appProps={appProps}
        />
        <UnauthenticatedRoute
          path='/forgotpassword'
          exact
          component={ForgotPassword}
          appProps={appProps}
        />
        <UnauthenticatedRoute
          path='/forgotpasswordconfirm'
          exact
          component={ForgotPasswordConfirm}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/upcomingdeliveries'
          exact
          component={UpcomingDeliveries}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/connectaccountingsystem'
          exact
          component={ConnectAccountingSystem}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/connecttoquickbooksresult'
          exact
          component={ConnectToQuickbooksResult}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/connecttosageresult'
          exact
          component={ConnectToSageResult}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/bankaccountsetup'
          exact
          component={BankAccountSetup}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/realmsettings'
          exact
          component={RealmSettings}
          appProps={appProps}
        />
        <AuthenticatedRoute path='/filehistory' exact component={FileHistory} appProps={appProps} />
        <AuthenticatedRoute
          path='/getcheckdata'
          exact
          component={GetCheckData}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/sagecompanyfilelist'
          exact
          component={SageCompanyFileList}
          appProps={appProps}
        />
        {/* Finally, catch all unmatched routes */}
        <UnauthenticatedRoute path='/*' component={Login} appProps={appProps} />
        {/*      <Route component={NotFound} />
         */}
      </Switch>
    );
  }
}

import React from "react";
import { Button, Modal, FormGroup, FormControl, ControlLabel } from "react-bootstrap"
import { API } from "aws-amplify";
import config from "../config"


class VerifyEmailDialog extends React.Component {
  constructor(props)
  {
      super(props);
      this.handleChange = this.handleChange.bind(this);
      this.handleClick_cancel = this.handleClick_cancel.bind(this);
      this.handleClick_verify = this.handleClick_verify.bind(this);

      this.callback = null
      this.state =
      {
          show: false,
          verification_code: ""
      };
  }

  displayDialog(callback){
    this.callback = callback
    this.setState(
      {
        show: true
      })
  }

  handleChange(e)
  {
    this.setState({ verification_code: e.target.value });
  }

  handleClick_cancel(answer)
  {
    this.setState(
      {
        show: false
      })
      if (this.callback){
        this.callback(answer)
      }
    }

    async handleClick_verify(answer)
    {
      try
      {
        var success = false;
        var body = {
          body:{
            "app_prefix": config.environment.APP_PREFIX,
            "verification_code":this.state.verification_code
          }
        }

        var apiResponse = await API.post("cloudautocheckissues", "/settings", body);

        if (apiResponse !== undefined)
        {
          if (apiResponse.status !== undefined)
          {
            if (apiResponse.status === "success")
            {
              success = true;
              this.setState({ show: false });

              var result = { success: true };
              this.callback(JSON.stringify(result));
            }
          }
        }

        if (!success)
        {
          throw new Error('Verification code request failed.');
        }
      }
      catch (e)
      {
        this.setState({ show: false });

        var resultError = { success: false, error: e.message };
        this.callback(JSON.stringify(resultError));
      }
    }

  show(bShow){
    this.setState({show:bShow})
  }
  render()
  {
      return (
        <div className="static-modal">
        <Modal
          {...this.props}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-lg">
              Verify
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
            Please check your email for the verification code. Enter the code and select the Verify button to complete the setup process.
            </p>

            <FormGroup controlId="confirmationCode">
              <ControlLabel>Verification Code</ControlLabel>
              <FormControl
                autoFocus
                type="text"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => {this.handleClick_cancel(false)}}>Cancel</Button>
            <Button disabled={this.state.verification_code.length === 0}  bsStyle="primary" onClick={() => {this.handleClick_verify(true)}}>Verify</Button>
          </Modal.Footer>
        </Modal>
        </div>
      );
    }

}
export default VerifyEmailDialog;

import React from 'react';
import { Button, SplitButton, MenuItem, Grid, Row } from 'react-bootstrap';
import { API } from 'aws-amplify';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import './FileHistory.css';
import ErrorDialog from './ErrorDialog';
import LoadingDialog from './LoadingDialog';
import PageHeader from '../components/PageHeader';
import TimeoutComponent from '../components/TimeoutComponent';

class SageCompanyFileList extends TimeoutComponent {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.code = props.location.sage_props.code;
    this.state = { companies: [], list_title: 'Select your company...' };
  }

  async componentDidMount() {
    try {
      this.refs.loadingDialog.show(true);

      var resultExchangeToken = await this.exchangeToken();
      if (!resultExchangeToken.error) {
        var resultGetCompanyFileList = await this.getCompanyFileList();
        this.setState({ companies: resultGetCompanyFileList.businesses });
      } else {
        throw new Error(resultExchangeToken.error);
      }
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      this.refs.loadingDialog.show(false);
    }
  }

  handleSelect(eventKey, event) {
    this.setState({ list_title: eventKey });
  }

  async handleClick() {
    for (var i = 0; i < this.state.companies.length; i++) {
      var company = this.state.companies[i];
      if (company.name === this.state.list_title) {
        var company_name = company.name;
        var realm_id = company.realm_id;
        var reply = await this.getSequenceNum(company_name, realm_id);
        //        var reply2 = await this.getCheckRegisters(reply.sequence_num);
        var file = { company_name: company_name, sequence_num: reply.sequence_num };

        this.props.history.push({
          pathname: '/connecttosageresult',
          file: file,
          error: reply.error,
          from: 'sagecompanyfilelist',
        });

        break;
      }
    }
  }

  exchangeToken() {
    console.log('API exchangeToken');
    return API.post('cloudautocheckissues', '/exchangeToken', {
      body: {
        accessCode: this.code,
        service: 'SAGE',
      },
    });
  }

  getCompanyFileList() {
    return API.get('cloudautocheckissues', '/sageBusinesses', {
      queryStringParameters: {
      },
    });
  }

  getSequenceNum(companyName, realmId) {
    return API.post('cloudautocheckissues', '/sageBusinesses', {
      body: {
        company_name: companyName,
        realm_id: realmId,
      },
    });
  }

/*  
  getCheckRegisters(sequenceNum) {
    return API.get('cloudautocheckissues', '/getCheckRegisters', {
      queryStringParameters: {
        sequence_num: sequenceNum,
      },
    });
  }
*/

  render() {
    return (
      <div className='SageCompanyFileList'>
        <PageHeader
          title='Company File List'
          info='Select the company file you would like to connect to for Positive Pay.'
        />

        <Grid style={{ width: '100%' }} fluid={true}>
          <Row>
            {/*}            
            <select id='company_list' onChange={this.handleSelect} value={''}>
              {this.state.companies.map((company) => (
                <option value={company.name}>{company.name}</option>
              ))}
            </select>
              */}

            <SplitButton
              title={this.state.list_title}
              id='company_list'
              onSelect={this.handleSelect}
            >
              {this.state.companies.map((company) => (
                <MenuItem eventKey={company.name}>{company.name}</MenuItem>
              ))}
            </SplitButton>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Button
              onClick={() => {
                this.props.history.push('/connectaccountingsystem');
              }}
              alt='...'
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: '10px' }}
              onClick={() => {
                this.handleClick();
              }}
              alt='...'
              disabled={this.state.list_title === 'Select your company...'}
            >
              Select
            </Button>
          </Row>
        </Grid>

        <LoadingDialog ref='loadingDialog' />
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
  
}

export default SageCompanyFileList;

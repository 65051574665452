import React from "react";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./ForgotPassword.css";
import { Button } from "react-bootstrap"
import ErrorDialog from './ErrorDialog'
import PageHeader from "../components/PageHeader"

class ForgotPassword extends React.Component {

  constructor(props)
  {
      super(props);
      this.handleChange_username = this.handleChange_username.bind(this);

      this.state =
      {
          username: ""
      };
  }

  async componentDidMount()
  {
  }


  handleChange_username(e) {
    this.setState({ username: e.target.value });
  }

  isBlank(str) {
      return (!str || /^\s*$/.test(str));
  }

  async onClick_ForgotPassword(event)
  {
    try {
      if (!this.isBlank(this.state.username)){
        await Auth.forgotPassword(this.state.username)
        this.props.history.push({pathname: "/forgotpasswordconfirm", state: { "username": this.state.username} });
      }else{
        this.refs.errorDialog.displayError("Missing Username", "The Username field must be entered")
      }

    } catch (e) {
      this.refs.errorDialog.displayError("Error", e.message)
    } finally {

    }
  }

  render() {
    return (
      <div className="ResetPassword">
        <PageHeader title="Reset Password" info="Enter your User ID and click Submit. Next, check your email for the verification and create a new password."/>
        <div style={{padding:'30px'}} className="widget">
        <form name='settingsForm'>
          <FormGroup style={{marginBottom:'30px'}} controlId="username" bsSize="large">
              <ControlLabel>Username:</ControlLabel>
              <FormControl type="text" placeholder="" onChange={this.handleChange_username}/>
          </FormGroup>
          <Button bsSize="large" style={{width:'100%', marginTop:'20px'}} onClick={() => {this.onClick_ForgotPassword()}} alt="...">Submit</Button>
        </form>
        <ErrorDialog ref="errorDialog"/>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;

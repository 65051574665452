export default {
  s3: {
    REGION: 'YOUR_S3_UPLOADS_BUCKET_REGION',
    BUCKET: 'YOUR_S3_UPLOADS_BUCKET_NAME',
  },
  apiGateway: {
    // DEV
    //REGION: 'us-west-2',
    //URL: 'https://ttrl5cqr8d.execute-api.us-west-2.amazonaws.com/Prod',

    // TEST
    //REGION: "us-west-2",
    //URL: "https://s13hqsrv0b.execute-api.us-west-2.amazonaws.com/Prod"

    // QA - WEST
    //REGION: 'us-west-2',
    //URL: 'https://tdhkaa6hp5.execute-api.us-west-2.amazonaws.com/Prod',

    // QA - EAST
    REGION: 'us-east-1',
    URL: 'https://jt8wk4ne9e.execute-api.us-east-1.amazonaws.com/Prod',

    //demo
    //REGION: "us-west-2",
    //URL: "https://pa4dv5gydj.execute-api.us-west-2.amazonaws.com/Prod"
  },
  cognito: {
    // DEV
    //REGION: 'us-west-2',
    //USER_POOL_ID: 'us-west-2_PC2NzWPRL',
    //APP_CLIENT_ID: '1eev59p9cnn19md32o93gopts7',
    //IDENTITY_POOL_ID: 'us-west-2:6767e34d-7537-4310-a714-e84ea1121e35',

    // test
    //REGION: "us-west-2",
    //USER_POOL_ID: "us-west-2_ZfrtX8tic",
    //APP_CLIENT_ID: "test_app_clientWeb",
    //IDENTITY_POOL_ID: "us-west-2:d30384ba-b3c5-40d3-88d0-b57643b3a0b4"

    // QA - WEST 
    //REGION: 'us-west-2',
    //USER_POOL_ID: 'us-west-2_XQ6x7uRt6',
    //APP_CLIENT_ID: '3psobmk1rmkp7pvbtb8ouafoei',
    //IDENTITY_POOL_ID: 'us-west-2:f2187443-ea83-47b9-b8b5-bcb679df9a4e',

    // QA - EAST 
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_FlxjCt1Hs',
    APP_CLIENT_ID: '2147r22829ge92310b3gck413e',
    IDENTITY_POOL_ID: 'us-east-1:d8fcbeac-f041-4e14-b8b2-991c791a01fb',

    // demo
    //REGION: "us-west-2",
    //USER_POOL_ID: "us-west-2_zVhLChmNO",
    //APP_CLIENT_ID: "rc3vamm9249uiuju2df375tt0",
    //IDENTITY_POOL_ID: "us-west-2:67340aba-d013-4b43-9872-462ee5fa129f"
  },
  intuit: {
    CLIENT_ID: 'L0GPj34mOT9n7iYLOoAXWIvXcdUJg8lusuwBMPYHUg2T5hZuzQ',
    // AMPLIFY
    // DEV - LOCALHOST
    //    REDIRECT_URI: "%3Dhttp%253a%252f%252flocalhost%253a3000%252f"

    // https://master.d1umhw82yw2r36.amplifyapp.com
  },
  environment: {
    // DEV
    //APP_PREFIX: 'cae',
    // TEST
    //APP_PREFIX: "test"
    // QA
    APP_PREFIX: 'QA',
    // demo
    //APP_PREFIX: "demo"
  },
  bank: {
    ACCOUNT_LENGTH: '15',
    // 1 min
    //TIMEOUT: 60000
    // 5 mins
    //TIMEOUT: 300000
    // 10 mins
    //TIMEOUT: 600000
    // 60 mins
    TIMEOUT: 3600000,
    SSO: 'false',
    PRODUCT_NAME: 'Auto Check Issues',
  },
};

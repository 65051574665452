import React from "react";
import { Button, Modal } from "react-bootstrap"


class ErrorDialog extends React.Component {
  constructor(props)
  {
      super(props);
      this.handleHide = this.handleHide.bind(this);

      this.state =
      {
          title:"",
          message:"",
          show: false
      };
  }

  displayError(title, message){
    this.setState(
      {
        title:title,
        message:message,
        show: true
      })
  }

  handleHide() {
    this.setState(
      {
        title:"",
        message:"",
        show: false
      })
    }

  show(bShow){
    this.setState({show:bShow})
  }
  render()
  {
      return (
        <div className="static-modal">
        <Modal
          {...this.props}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-lg">
              {this.state.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
            {this.state.message}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleHide}>Close</Button>
          </Modal.Footer>
        </Modal>
        </div>
      );
    }

}
export default ErrorDialog;

import React from 'react';
//import { Auth } from 'aws-amplify';
import { FormGroup, FormControl } from 'react-bootstrap';

class EulaText extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {}
   
  render() {
    return (
      <div className='EulaText' style={{ marginBottom: '30px' }}>
        <span>
<p>
  &lt;GST Customer Name&gt; &lt;Product Name&gt; License Agreement
</p>
<p>
  &lt;GST CUSTOMER NAME&gt; IS WILLING TO LICENSE THE SOFTWARE IDENTIFIED BELOW TO YOU ONLY UPON THE CONDITION THAT YOU ACCEPT ALL OF THE TERMS CONTAINED IN THIS &lt;PRODUCT NAME&gt; LICENSE AGREEMENT ("AGREEMENT"). PLEASE READ THE AGREEMENT CAREFULLY. BY DOWNLOADING OR INSTALLING THIS SOFTWARE, YOU ACCEPT THE TERMS OF THE AGREEMENT. INDICATE ACCEPTANCE BY SELECTING THE "I ACCEPT" OPTION AT THE BOTTOM OF THE AGREEMENT. IF YOU ARE NOT WILLING TO BE BOUND BY ALL THE TERMS, SELECT THE "I DO NOT ACCEPT" OPTION AND THE DOWNLOAD OR INSTALLATION PROCESS WILL NOT CONTINUE. 
</p>
<ol>
  <li>
    Customer shall not allow the Software to be accessed or used by any person or entity not authorized by Customer.
  </li>
  <li>
    Customer shall not allow the Software to be used or accessed, and shall not authorize any person or entity to use or access the Software, on any computer not owned and under the exclusive control of Customer.
  </li>
  <li>
    Customer shall use, and shall allow the use of, the Software for Customer’s internal purposes only and only in “object code” format.
  </li>
  <li>
    Customer shall not disclose the Software to any person or entity other than its employees who will use the Software to perform his/her its duties for the Customer.
  </li>
  <li>
    Customer shall not, nor shall it allow any other party to, decompile, disassemble or reverse engineer the Software or any part thereof, or attempt to do the foregoing. 
  </li>
  <li>
    Customer shall not, nor shall it allow any other party to, inspect the functionality or derive a “source code” format version of the Software, or to enhance, modify or prepare derivative works of the Software, or attempt to do the foregoing.
  </li>
  <li>
    Customer shall not use or integrate the Software or any part thereof with any other software.
  </li>
  <li>
    Each party acknowledges and agrees that the Software is owned by Generated Systems Technologies, Inc. (“GST”), and GST is a third party beneficiary of this Agreement. In that regard, GST may enforce the terms of this Agreement. Each party agrees that it will take no action to impair the rights of GST in and to the Software.
  </li>
  <li>
    Customer agrees to notify Bank immediately in the event that it becomes aware or suspects that the Software is being used or disclosed in a manner that may violate this Agreement or that may negatively impact GST.  Such notice shall describe the breach or alleged breach in as much detail as the applicable party is in possession of.
  </li>
  <li>
    Customer shall not copy the Software, in whole or in part, except as permitted by law, and then only with the inclusion of all copyright, proprietary and other notices. 
  </li>
  <li>
    This Agreement shall be governed by the laws of the State of Georgia, without regard to its principles of conflicts of laws.                           
  </li>    
</ol>
        </span>

      </div>
    );
  }
}

export default EulaText;

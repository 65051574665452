import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { API } from 'aws-amplify';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import './FileHistory.css';
import config from '../config';
import ErrorDialog from './ErrorDialog';
import LoadingDialog from './LoadingDialog';
import PageHeader from '../components/PageHeader';
import Moment from 'react-moment';
import TimeoutComponent from '../components/TimeoutComponent';

class FileHistory extends TimeoutComponent {
  constructor(props) {
    super(props);
    this.batches = [];
    this.rowClicked.bind(this);
    this.state = { isLoaded: false };
  }

  async componentDidMount() {
    try {
      this.refs.loadingDialog.show(true);
      var reply = await this.getBatchList();
      if (reply.records) {
        this.batches = reply.records;
      }
      this.setState({ isLoaded: true });
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      this.refs.loadingDialog.show(false);
    }
  }

  rowClicked(batch) {
    this.props.history.push({ pathname: '/getcheckdata', state: { batch_id: batch.batch_id, sequence_num: this.props.location.state.file.sequence_num } });
  }

  getBatchList() {
    return API.get('cloudautocheckissues', '/getBatchList', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
        sequence_num: this.props.location.state.file.sequence_num,
      },
    });
  }

  render() {
    return (
      <div className='History'>
        <PageHeader
          title='Check File History'
          info='History of check issue file delivery for your company accounts.'
        />
        <Button
          style={{ marginBottom: '20px' }}
          onClick={() => {
            this.props.history.goBack();
          }}
          alt='...'
        >
          Back
        </Button>
        <Table style={{ backgroundColor: 'white' }} bordered hover responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th className='right-text'># of Items</th>
              <th className='right-text'>Amount</th>
              <th>Account Number</th>
              <th>Status</th>
              <th className='center-text'>Details</th>
            </tr>
          </thead>
          <tbody>
            {this.batches.map((batch) => (
              <tr key={batch.batch_id}>
                <td>
                  <Moment
                    format='MM/DD/YYYY hh:mm A'
                    parse='YYYY/MM/DD HH:mm:ssZ'
                    date={batch.import_date_time}
                  />
                </td>
                <td className='right-text'>{batch.number_of_items}</td>
                <td className='right-text'>
                  {Number(batch.amount).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td>{batch.account_num}</td>
                <td>{batch.status}</td>
                <td>
                  <center>
                    {batch.number_of_items > 0 && (
                      <Button
                        bsSize='xsmall'
                        onClick={() => {
                          this.rowClicked(batch);
                        }}
                      >
                        View
                      </Button>
                    )}
                  </center>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button
          onClick={() => {
            this.props.history.goBack();
          }}
          alt='...'
        >
          Back
        </Button>
        <LoadingDialog ref='loadingDialog' />
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
}

export default FileHistory;

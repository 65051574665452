import React from "react";

class TimeoutComponent extends React.Component
{
  constructor(props)
  {
    super(props);

    sessionStorage['last_activity_time'] = new Date().getTime();
  }

}

export default TimeoutComponent;

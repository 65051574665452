import React from 'react';
//import { Auth } from 'aws-amplify';
import { Button, FormGroup, FormControl, ControlLabel, Radio } from 'react-bootstrap';
import './Eula.css';
import ErrorDialog from './ErrorDialog';
import PageHeader from '../components/PageHeader';
import { API } from 'aws-amplify';
import config from '../config';
import EulaText from '../components/EulaText';

class Eula extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange_accept = this.handleChange_accept.bind(this);
    this.handleClick_ContinueButton = this.handleClick_ContinueButton.bind(this);

    this.state = {
      accept: 'false',
    };
  }

  async componentDidMount() {}

  getQBFiles() {
    return API.get('cloudautocheckissues', '/getQBFileList', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
        user_id: this.props.location.state.user_id,
        eula_accepted: this.state.accept,
      },
    });
  }

  handleChange_accept(e) {
    this.setState({ accept: e.target.value });
  }

  async handleClick_ContinueButton() {
    try {
      var data = await this.getQBFiles();

      if (data.eula_accepted === true)
      {
        if (data.files.length > 0) {
          this.props.history.push('/upcomingdeliveries');
        } else {
          this.props.history.push('/connectaccountingsystem');
        }
      }
      else
      {
        throw new Error("Acceptance of EULA was not successful.");
      }

    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    }
  }
   
  render() {
    return (
      <div className='Eula'>
        <PageHeader title='End-User License Agreement' info='Please read the following license agreement carefully.' />
        <div style={{ padding: '30px' }} className='widget'>
          <form name='eulaForm' autocomplete='off'>
            <EulaText/>
            <Radio
                name='radioGroup'
                checked={this.state.accept === 'true'}
                value= 'true'
                onChange={this.handleChange_accept}
            >
                I accept the terms in the License Agreement.
            </Radio>
            <Radio
                name='radioGroup'
                checked={this.state.accept === 'false'}
                value= 'false'
                onChange={this.handleChange_accept}
            >
                I do not accept the terms in the License Agreement.
            </Radio>

            <Button
              style={{ marginTop: '20px' }}
              disabled={this.state.accept === 'false'}
              bsSize='large'
              onClick={() => {
                this.handleClick_ContinueButton();
              }}
              alt='...'
            >
              Continue
            </Button>
          </form>
        </div>
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
}

export default Eula;

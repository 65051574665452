import React from 'react';
import { API } from 'aws-amplify';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import './UpcomingDeliveries.css';
import Moment from 'react-moment';
import config from '../config';
import ErrorDialog from './ErrorDialog';
import LoadingDialog from './LoadingDialog';
import ConfirmDialog from './ConfirmDialog';
import '../css/branding.css';
import TimeoutComponent from '../components/TimeoutComponent';

class UpcomingDeliveries extends TimeoutComponent {
  constructor(props) {
    super(props);
    this.state = { files: [], retrieval_schedule_msg: '' };
    this.fileToDelete = null;
    this.onClick_HistoryButton = this.onClick_HistoryButton.bind(this);
    this.onClick_RunNowButton = this.onClick_RunNowButton.bind(this);
    this.onClick_ScheduleButton = this.onClick_ScheduleButton.bind(this);
    this.onClick_BankAccountsButton = this.onClick_BankAccountsButton.bind(this);
    this.onClick_RemoveButton = this.onClick_RemoveButton.bind(this);
    this.handleAnswer = this.handleAnswer.bind(this);
  }

  async componentDidMount() {
    try {
      this.refs.loadingDialog.show(true);
      var reply = await this.getQBFileList();

      if (!reply.error) {
        console.log('!reply.error');
        this.setState({ files: reply.files, retrieval_schedule_msg: reply.retrieval_schedule_msg });
      } else {
        console.log('reply.error');
        this.refs.errorDialog.displayError('Error', reply.error);
      }
    } catch (e) {
      console.log('catch');
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      console.log('finally');
      this.refs.loadingDialog.show(false);
    }
  }

  getQBFileList() {
    console.log('getQBFileList()');
    return API.get('cloudautocheckissues', '/getQBFileList', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
      },
    });
  }

  getQuickbooksInfo() {
    return API.get('cloudautocheckissues', '/getOAuthURL', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
      },
    });
  }

  async handleAnswer(answer) {
    if (answer) {
      this.deleteFile(this.fileToDelete);
    }
    this.fileToDelete = null;
  }

  runNow(file) {
    return API.get('cloudautocheckissues', '/runNow', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
        sequence_num: file.sequence_num,
      },
    });
  }
  onClick_HistoryButton(file) {
    this.props.history.push({
      pathname: '/filehistory',
      state: { file: file, from: 'upcomingdeliveries' },
    });
  }

  async onClick_RunNowButton(file, event) {
    try {
      this.refs.loadingDialog.show(true);
      var reply = await this.runNow(file);
      this.refs.loadingDialog.show(false);
      if (reply.status === 'success') {
        this.refs.errorDialog.displayError(
          'Info',
          'Check issue data is being retrieved from your accounting system. When the check issue file is delivered to the bank, you will be notified per your instructions.'
        );
      } else if (reply.error) {
        this.refs.errorDialog.displayError('Error', reply.error);
      }
    } catch (e) {
      this.refs.loadingDialog.show(false);
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
    }
  }

  onClick_ScheduleButton(file, event) {
    this.props.history.push({
      pathname: '/realmsettings',
      state: { file: file, from: 'upcomingdeliveries' },
    });
  }

  onClick_BankAccountsButton(file, event) {
    this.props.history.push({
      pathname: '/bankaccountsetup',
      state: { file: file, from: 'upcomingdeliveries' },
    });
  }

  deleteQBFile(file) {
    return API.post('cloudautocheckissues', '/deleteQBFile', {
      body: {
        app_prefix: config.environment.APP_PREFIX,
        sequence_num: file.sequence_num,
      },
    });
  }

  async deleteFile(file) {
    try {
      this.refs.loadingDialog.show(true);
      var reply = await this.deleteQBFile(file);
      this.setState({ files: [] });
      reply = await this.getQBFileList();
      this.setState({ files: reply.files, retrieval_schedule_msg: reply.retrieval_schedule_msg });
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      this.refs.loadingDialog.show(false);
    }
  }

  async onClick_RemoveButton(file, event) {
    this.fileToDelete = file;
    this.refs.confirmDialog.displayConfirmBox(
      'Disconnect?',
      'Are you sure you want to disconnect from the accounting system?',
      this.handleAnswer
    );
  }

  async onClick_AddFile(event) {
    this.refs.loadingDialog.show(true);
    var qbInfo = await this.getQuickbooksInfo();
    this.props.history.push({
      pathname: '/connectaccountingsystem',
      state: { url: qbInfo.url, from: 'upcomingdeliveries' },
    });
  }

  render() {
    return (
      <div className='UpcomingDeliveries'>
        <div style={{ width: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <h3 style={{ width: '100%' }}>Connected Companies</h3>
          <Button
            bsStyle='default'
            bsSize='small'
            onClick={() => {
              this.onClick_AddFile();
            }}
          >
            Connect a Company
          </Button>
        </div>

        <p style={{ marginBottom: '20px' }}>{this.state.retrieval_schedule_msg}</p>

        <Grid style={{ marginTop: '20px', width: '100%' }} fluid={true}>
          {this.state.files.map((file) => (
            <Row
              key={file.company_name}
              style={{ marginBottom: '20px' }}
              className='show-grid widget'
            >
              <Col xs={12} className='centered-col'>
                <div
                  style={{
                    width: '100%',
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <h5 className='card-title' style={{ width: '100%' }}>
                    {file.company_name}
                  </h5>
                  {/*                  
                  <h5
                    className='card-title'
                    style={{ textAlign: 'left', marginRight: '20px', verticalAlign: 'center' }}
                  >
                    {file.system}
                  </h5>
                  */}
                  <Button
                    bsStyle='default'
                    bsSize='small'
                    onClick={() => {
                      this.onClick_RemoveButton(file);
                    }}
                  >
                    Disconnect
                  </Button>
                </div>
                <hr style={{ width: '100%', marginTop: '10px' }} />
              </Col>

              <Col xs={12} sm={4} style={{ marginBottom: '20px' }} className='centered-col'>
                <h5>
                  <span className='branded-text'>Last Check Retrieval</span>
                </h5>
                {file.last_run ? (
                  <Moment format='MM/DD/YYYY hh:mm A' date={file.last_run} />
                ) : (
                  <p>Awaiting First Retrieval</p>
                )}
              </Col>

              <Col xs={6} sm={4} className='centered-col'>
                <Button
                  className='icon-button branded-text'
                  onClick={() => {
                    this.onClick_HistoryButton(file);
                  }}
                  data-company_name={file.company_name}
                  data-sequence_num={file.sequence_num}
                  value={file.company_name}
                >
                  History{' '}
                  <img
                    src={process.env.PUBLIC_URL + '/recent-search.svg'}
                    className='button-icon-flipped'
                    alt='...'
                  />
                </Button>
                <Button
                  className='icon-button branded-text'
                  onClick={() => {
                    this.onClick_RunNowButton(file);
                  }}
                  data-company_name={file.company_name}
                  data-sequence_num={file.sequence_num}
                  value={file.company_name}
                >
                  Run Now{' '}
                  <img
                    src={process.env.PUBLIC_URL + '/play.svg'}
                    className='button-icon'
                    alt='...'
                  />
                </Button>
              </Col>

              <Col xs={6} sm={4} className='centered-col'>
                <Button
                  className='icon-button branded-text'
                  onClick={() => {
                    this.onClick_ScheduleButton(file);
                  }}
                  data-company_name={file.company_name}
                  data-sequence_num={file.sequence_num}
                  value={file.company_name}
                >
                  Settings{' '}
                  <img
                    src={process.env.PUBLIC_URL + '/settings-24px.svg'}
                    className='button-icon'
                    alt='...'
                  />
                </Button>
                <Button
                  className='icon-button branded-text'
                  onClick={() => {
                    this.onClick_BankAccountsButton(file);
                  }}
                >
                  Bank Accounts{' '}
                  <img
                    src={process.env.PUBLIC_URL + '/briefcase.svg'}
                    className='button-icon'
                    alt='...'
                  />
                </Button>
              </Col>
            </Row>
          ))}
        </Grid>
        <ErrorDialog ref='errorDialog' />
        <LoadingDialog ref='loadingDialog' />
        <ConfirmDialog ref='confirmDialog' />
      </div>
    );
  }
}

export default UpcomingDeliveries;

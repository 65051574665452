import React from "react";
import { Button, Table } from "react-bootstrap"
import { API } from "aws-amplify";
import "./GetCheckData.css"
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import config from "../config"
import LoadingDialog from "./LoadingDialog"
import ErrorDialog from "./ErrorDialog"
import Moment from 'react-moment';
import TimeoutComponent from "../components/TimeoutComponent"
import BootstrapTable from "react-bootstrap-table-next";

class GetCheckData extends TimeoutComponent {

  constructor(props) {
          super(props);
          this.transactions = []
          this.state = { isLoaded: false, batch_id: this.props.location.state.batch_id, account: "" };
  }

  async componentDidMount()
  {
    try {
      this.refs.loadingDialog.show(true)
        var reply = await this.getCheckData();
        if (reply.records){
          this.transactions = reply.records.sort(this.compare);
          this.state.account = this.transactions[0].bank_account;
        }
        this.setState({isLoaded: true})
    } catch (e) {
      this.refs.errorDialog.displayError("Error",e.message);
    } finally {
      this.refs.loadingDialog.show(false)
    }
  }

  rowClicked(batch){
    alert(batch.batch_id)

  }

  getCheckData() {
    return API.get("cloudautocheckissues", "/getCheckDataForBatch", {
      queryStringParameters:{
        "app_prefix": config.environment.APP_PREFIX,
        "batch_id": this.props.location.state.batch_id,
        "sequence_num": this.props.location.state.sequence_num,
      },
    });
  }

  compare( a, b ) {
   if ( a.bank_account < b.bank_account ){
     return -1;
   }else if ( a.bank_account > b.bank_account ){
     return 1;
   }else {
     //secondary sort
     if ( a.issue_date < b.issue_date){
       return -1
     }else if (a.issue_date < b.issue_date){
       return 1
     }
   }
   return 0;
 }
  render() {

    const columns = [     
      {
        dataField: "issue_date",
        text: "Issue Date",
      },    
      {
        dataField: "int_check_num",
        textAlign: "right",
        text: "Check #",
        headerAlign: "right",
        align: "right",
        sort: true,
      },
      {
        dataField: "type",
        text: "Type",
      },      
      {
        dataField: "amount",
        headerAlign: "right",
        align: "right",
        text: "Amount",
      },
      {
        dataField: "payee_name",
        text: "Payee",
      },          
      {
        dataField: "text",
        text: "Memo",
      },
    ];

        return (
          <div className="Scheduler">
            <h3>File Details</h3>
            <hr style={{marginTop:'0px', marginBottom:'10px'}}/>
            <p style={{marginBottom:'20px'}}>The check issue file that was sent to the bank included the following items.</p>

            <h5 style={{marginBottom:'10px'}}>Batch ID: {this.props.location.state.batch_id}</h5>
            <h5 style={{marginBottom:'20px'}}>Account: {this.state.account}</h5>

            <Button style={{marginBottom:"20px"}} onClick={() => {this.props.history.goBack()}} alt="...">Back</Button>
            <Button style={{marginLeft:"20px", marginBottom:"20px"}} onClick={() => {this.props.history.push("/upcomingdeliveries")}} alt="...">Return to Companies List</Button>

            <BootstrapTable
              keyField="company_name"
              data={this.transactions}
              columns={columns}
              rowStyle={{ backgroundColor: "white" }}
              sort={{ dataField: "int_check_num", order: "desc" }}
            />

{/*
              rowStyle={{ backgroundColor: "whitesmoke" }}


            <Table style={{backgroundColor:'white'}} striped bordered hover responsive>
              <thead>
                <tr>
                  <th className='center-text'>Issue Date</th>
                  <th className="right-text">Check&nbsp;#</th>
                  <th className='center-text'>Type</th>
                  <th className='right-text'>Amount</th>
                  <th>Payee</th>
                  <th>Memo</th>
                </tr>
              </thead>
              <tbody>
              {this.transactions.map((transaction) => (
                <tr key={transaction.trans_id}>
                  <td className='center-text'><Moment format='MM/DD/YYYY' date={transaction.issue_date}/></td>
                  <td className='right-text'>{transaction.check_num}</td>
                  <td className='center-text'>{transaction.type}</td>
                  <td className='right-text'>{Number(transaction.amount).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</td>
                  <td>{transaction.payee_name}</td>
                  <td>{transaction.text}</td>
                </tr>
              ))}

              </tbody>
            </Table>
*/}

            <Button  onClick={() => {this.props.history.goBack()}} alt="...">Back</Button>
            <Button style={{marginLeft:"20px"}} onClick={() => {this.props.history.push("/upcomingdeliveries")}} alt="...">Return to Companies List</Button>
            <LoadingDialog ref="loadingDialog"/>
            <ErrorDialog ref="errorDialog"/>
          </div>
        );
    }
}

export default GetCheckData;

import React from 'react';
import { FormGroup, FormControl, ControlLabel, Grid, Row, Col, Button } from 'react-bootstrap';
import { API } from 'aws-amplify';
import config from '../config';
import './BankAccountSetup.css';
import '../css/branding.css';
import ErrorDialog from './ErrorDialog';
import LoadingDialog from './LoadingDialog';
import PageHeader from '../components/PageHeader';
import TimeoutComponent from '../components/TimeoutComponent';

function getQuickbooksInfo() {
  return API.get('cloudautocheckissues', '/getOAuthURL', {
    queryStringParameters: {
      app_prefix: config.environment.APP_PREFIX,
    },
  });
}

class BankAccountSetup extends TimeoutComponent {
  constructor(props) {
    super(props);
    this.accounts = [];
    this.onClick_PreviousButton = this.onClick_PreviousButton.bind(this);
    this.onClick_MapAccounts = this.onClick_MapAccounts.bind(this);
    this.isSetup = false;
  }

  async componentDidMount() {
    try {
      this.refs.loadingDialog.show(true);
      var reply = await this.getCheckRegisters();
      if (reply.accounts) {
        var mappings = await this.getAccountMapping();
        if (mappings.error) {
          this.refs.errorDialog.displayError('Error', mappings.error);
        } else {
          this.accounts = reply.accounts;
          if (typeof mappings.mappings != 'object' || Object.keys(mappings.mappings).length === 0) {
            mappings.mappings = '{}';
            this.isSetup = false;
          } else {
            this.isSetup = true;
          }
          this.buildMappingList(this.accounts, mappings.mappings);
          this.forceUpdate();
        }
      } else {
        this.refs.errorDialog.displayError('Error', reply.error);
      }
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      this.refs.loadingDialog.show(false);
    }
  }

  async onClick_PreviousButton(event) {
    try {
      this.refs.loadingDialog.show(true);
      var qbInfo = await getQuickbooksInfo();
      if (!this.isSetup) {
        var reply = await this.removeConnectedAccount();
        if (reply.error) {
          this.refs.errorDialog.displayError('Error', reply.error);
        }
      }
      this.refs.loadingDialog.show(false);
      this.props.history.push({ pathname: '/connectaccountingsystem', state: { url: qbInfo.url } });
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      if (this.refs.loadingDialog !== undefined) {
        this.refs.loadingDialog.show(false);
      }
    }
  }

  async onClick_MapAccounts(event) {
    try {
      var newMappings = {};
      var accountMapped = false;
      for (var i = 0; i < this.accounts.length; i++) {
        var account = this.accounts[i];
        var textField = document.getElementById('mapping' + account.row);
        var accountStr = textField.value.trim();

        if (accountStr.length > 0) {
          var j;
          for (j = 0; j < accountStr.length; j++) {
            if (isNaN(accountStr.charAt(j))) {
              this.refs.errorDialog.displayError('Error', 'Bank Account Number is not valid.');
              return;
            }
          }
          if (accountStr.length >= 4) {
            newMappings[account.Id] = accountStr;
            accountMapped = true;
          } else {
            this.refs.errorDialog.displayError(
              'Error',
              'Bank Account Numbers must be 4 digits or more.'
            );
            return;
          }
        }
      }
      if (!accountMapped && this.accounts.length > 0) {
        this.refs.errorDialog.displayError('Error', 'You must map at least one Bank Account.');
        return;
      }
      this.refs.loadingDialog.show(true);
      var reply = await this.saveAccountMappings(newMappings);
      this.refs.loadingDialog.show(false);
      if (reply.message) {
        this.refs.errorDialog.displayError('Info', 'Your account mappings have been saved.');
        if (this.props.location.state.from === 'upcomingdeliveries') {
          this.props.history.push('/upcomingdeliveries');
        } else if (
          this.props.location.state.from === 'connecttoquickbooksresult' ||
          this.props.location.state.from === 'connecttosageresult'
        ) {
          this.props.history.push({
            pathname: '/realmsettings',
            state: { file: this.props.location.state.file, from: 'bankaccountsetup' },
          });
        }
      }
    } catch (e) {
      this.refs.loadingDialog.show(false);
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
    }
  }

  buildMappingList(registers, mappings) {
    for (var i = 0; i < registers.length; i++) {
      registers[i].row = i;
      if (mappings[registers[i].Id]) {
        registers[i].mapping = mappings[registers[i].Id];
      }
    }
  }

  getCheckRegisters() {
    return API.get('cloudautocheckissues', '/getCheckRegisters', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
        sequence_num: this.props.location.state.file.sequence_num,
      },
    });
  }

  removeConnectedAccount() {
    return API.post('cloudautocheckissues', '/deleteQBFile', {
      body: {
        app_prefix: config.environment.APP_PREFIX,
        sequence_num: this.props.location.state.file.sequence_num,
      },
    });
  }

  getAccountMapping() {
    return API.get('cloudautocheckissues', '/accountMappings', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
        sequence_num: this.props.location.state.file.sequence_num,
      },
    });
  }

  saveAccountMappings(mappings) {
    return API.post('cloudautocheckissues', '/accountMappings', {
      body: {
        app_prefix: config.environment.APP_PREFIX,
        accessCode: this.code,
        account_mappings: mappings,
        sequence_num: this.props.location.state.file.sequence_num,
      },
    });
  }

  handleChange_account(e) {}

  render() {
    return (
      <div className='BankAccountSetup'>
        <PageHeader
          title='Bank Account Setup'
          info='Your check registers have been retrieved from the accounting system. Enter the bank account for each register that is used for Positive Pay.'
        />
        <p style={{ marginBottom: '30px' }}>
          <ControlLabel>{this.props.location.state.file.company_name}</ControlLabel>
        </p>
        <form ref='registerMapping'>
          <Grid style={{ width: '100%' }} fluid={true}>
            {this.accounts.map((account) => (
              <Row key={account.row} style={{ marginBottom: '20px' }} className='show-grid widget'>
                <Row style={{ margin: '5px' }}>
                  <Col xs={12} style={{ alignItems: 'left' }}>
                    <label>Register: {account.Name}</label>
                    <hr style={{ width: '100%', marginTop: '0px' }} />
                  </Col>
                </Row>
                <Row style={{ margin: '5px' }}>
                  <Col xs={12} style={{ alignItems: 'left' }}>
                    <FormGroup style={{ maxWidth: '450px', marginBottom: '30px' }}>
                      <ControlLabel>Bank Account:</ControlLabel>
                      <FormControl
                        maxLength={config.bank.ACCOUNT_LENGTH}
                        type='text'
                        placeholder=''
                        defaultValue={account.mapping}
                        onChange={this.handleChange_account}
                        id={'mapping' + account.row}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Row>
            ))}
          </Grid>
          {this.displayButtons()}
        </form>
        <ErrorDialog ref='errorDialog' />
        <LoadingDialog ref='loadingDialog' />
      </div>
    );
  }

  displayButtons() {
    if (
      this.props.location.state.from === 'connecttoquickbooksresult' ||
      this.props.location.state.from === 'connecttosageresult'
    ) {
      return (
        <div>
          <Button
            onClick={() => {
              this.onClick_PreviousButton();
            }}
            alt='...'
          >
            Start Over
          </Button>
          <Button
            style={{ marginLeft: '15px' }}
            onClick={() => {
              this.onClick_MapAccounts();
            }}
            alt='...'
          >
            Next
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <Button
            onClick={() => {
              this.props.history.goBack();
            }}
            alt='...'
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: '15px' }}
            onClick={() => {
              this.onClick_MapAccounts();
            }}
            alt='...'
          >
            Submit
          </Button>
        </div>
      );
    }
  }
}

export default BankAccountSetup;

import React, { useEffect } from 'react';
import '../App.css';
import '../css/branding.css';
import AWS from 'aws-sdk';
import PageHeader from '../components/PageHeader';
import { API, Auth } from 'aws-amplify';
import config from '../config';
import queryString from 'query-string';

//-----------------------------------------------------------------------------
// Home acts a default landing page after integrating with Sage or QuickBooks
// files. Routing to the correct page is done after parsing the query string.
//-----------------------------------------------------------------------------
// Query String Parameters:
//    From Sage:
//      code
//      country
//      state: "sage"
//    From QuickBooks
//      code
//      realmId
//      state
//    From ?
//      identity
//-----------------------------------------------------------------------------

function Home(props) {
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    console.log('Home onLoad()');

    try {
      var query_string_params = queryString.parse(window.location.search);

      if ('identity' in query_string_params) {
        sessionStorage['awsIdentity'] = query_string_params.identity;
        sessionStorage['awsToken'] = query_string_params.token;

        AWS.config.region = config.cognito.REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
          IdentityId: query_string_params.identity,
          Logins: {
            'cognito-identity.amazonaws.com': query_string_params.token,
          },
        });
        AWS.config.credentials.refresh(async function (err) {
          if (err) {
            console.log(err);
            props.history.push({ pathname: '/loggedout', state: { error: err.message } });
          } else {
            sessionStorage['last_activity_time'] = new Date().getTime();

            var data = await getQBFileList();
            console.log(data);
            if (data.files.length > 0) {
              console.log('/upcomingdeliveries');
              props.history.push('/upcomingdeliveries');
            } else {
              console.log('/connectaccountingsystem');
              props.history.push('/connectaccountingsystem');
            }
          }
        });
      } else if ('state' in query_string_params && query_string_params.state === 'sage') {
        process_sage_response(query_string_params);
      } else if ('realmId' in query_string_params || 'error' in query_string_params) {
        process_quickbooks_response(query_string_params);
      }
    } catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }

  async function process_sage_response(query_string_params)
  {
    console.log('Home process_sage_response()');
    if (config.bank.SSO === 'true') {

      AWS.config.region = config.cognito.REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
        IdentityId: sessionStorage['awsIdentity'],
        Logins: {
          'cognito-identity.amazonaws.com': sessionStorage['awsToken'],
        },
      });

      AWS.config.credentials.refresh(async function (err) {
        console.log('AWS.config.credentials.refresh');
        if (err) {
          console.log(err);
          props.history.push({ pathname: '/loggedout', state: { error: err.message } });
        } else {
          sessionStorage['last_activity_time'] = new Date().getTime();

          props.history.push({
            pathname: '/sagecompanyfilelist',
            sage_props: query_string_params,
          });
        }
      });
    } else {
      sessionStorage['last_activity_time'] = new Date().getTime();

      await Auth.currentSession();
      props.setIsAuthenticated(true);

      props.history.push({ pathname: '/sagecompanyfilelist', sage_props: query_string_params });
//      props.history.push({ pathname: '/sagecompanyfilelist', state: { sageprops: query_string_params } });
    }
  }

  async function process_quickbooks_response(query_string_params)
  {
    console.log('Home process_quickbooks_response()');
    if (config.bank.SSO === 'true')
    {
      AWS.config.region = config.cognito.REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
        IdentityId: sessionStorage['awsIdentity'],
        Logins: {
          'cognito-identity.amazonaws.com': sessionStorage['awsToken'],
        },
      });

      AWS.config.credentials.refresh(async function (err) {
        console.log('AWS.config.credentials.refresh');
        if (err) {
          console.log(err);
          props.history.push({ pathname: '/loggedout', state: { error: err.message } });
        } else {
          sessionStorage['last_activity_time'] = new Date().getTime();

          props.history.push({
            pathname: '/connecttoquickbooksresult',
            quickbooks_props: query_string_params,
          });
        }
      });
    } 
    else
    {
      sessionStorage['last_activity_time'] = new Date().getTime();

      await Auth.currentSession();
      props.setIsAuthenticated(true);

      props.history.push({
        pathname: '/connecttoquickbooksresult',
        quickbooks_props: query_string_params,
      });
    }
  }

  function getQBFileList() {
    console.log('getQBFileList()');
    return API.get('cloudautocheckissues', '/getQBFileList', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
      },
    });
  }

  return (
    <div>
      <PageHeader title='' info='' />
    </div>
  );
}

export default Home;

import './ConnectToSageResult.css';
import React from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import LoadingDialog from './LoadingDialog';
import TimeoutComponent from '../components/TimeoutComponent';

class ConnectToSageResult extends TimeoutComponent {
  constructor(props) {
    super(props);

    this.state = { isLoaded: false, file: props.location.file };
    this.error = props.location.error;
  }

  async componentDidMount() {
    this.refs.loadingDialog.show(true);
    this.state.isLoaded = true;
    this.setState({ isloaded: true });
    this.refs.loadingDialog.show(false);
  }

  onClick_ContinueButton() {
    this.props.history.push({
      pathname: '/bankaccountsetup',
      state: { file: this.state.file, from: 'connecttosageresult' },
    });
  }

  render() {
    if (this.state.isLoaded) {
      if (!this.error) {
        return (
          <div>
            <Grid
              className='ConnectToSageResult'
              style={{ marginTop: '100px', width: '100%' }}
              fluid={true}
            >
              <Row>
                <Col
                  xs={12}
                  style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}
                >
                  <h4 style={{ color: '#0c6aae', margin: '25px' }}>SUCCESS</h4>
                  <img
                    style={{ height: '100px' }}
                    src={process.env.PUBLIC_URL + '/success.png'}
                    alt='...'
                  />
                  <div id='message_success'>
                    {' '}
                    <p style={{ margin: '25px' }}>
                      You have successfully connected to {this.state.file.company_name}.
                    </p>{' '}
                  </div>
                  <Button
                    style={{ height: '40px', background: '#0c6aae', color: 'white' }}
                    onClick={() => {
                      this.onClick_ContinueButton();
                    }}
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </Grid>
            <LoadingDialog ref='loadingDialog' />
          </div>
        );
      } else {
        return (
          <div>
            <Grid
              className='ConnectToQuickbooksResult'
              style={{ marginTop: '100px', width: '100%' }}
              fluid={true}
            >
              <Row>
                <Col
                  xs={12}
                  style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}
                >
                  <h4 style={{ color: 'red', margin: '25px' }}>FAILURE</h4>
                  <img
                    style={{ height: '100px' }}
                    src={process.env.PUBLIC_URL + '/failure.png'}
                    alt='...'
                  />
                  <div id='message_failure'>
                    <p style={{ margin: '25px' }}> {this.error} </p>{' '}
                  </div>
                  <Button
                    style={{ height: '40px', background: '#0c6aae', color: 'white' }}
                    onClick={() => this.props.history.push('/connectaccountingsystem')}
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </Grid>
            <LoadingDialog ref='loadingDialog' />
          </div>
        );
      }
    } else {
      return (
        <div>
          <LoadingDialog ref='loadingDialog' />
        </div>
      );
    }
  }
}

export default ConnectToSageResult;

import React from "react";

class ConnectToQuickbooksButton extends React.Component
{
  handleClick = (e) => {
    e.preventDefault();
  };

  render()
  {
    return (
      <a href={this.props.qbURL}>
        <img style={{height:'36px'}} src={process.env.PUBLIC_URL + '/C2QB_green_btn_med_default.png'} alt="Connect to QuickBooks"/>
      </a>
    );
  }
}

export default ConnectToQuickbooksButton;

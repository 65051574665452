import React from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import ConnectToQuickbooksButton from '../components/ConnectToQuickbooksButton';
import ConnectToXeroButton from '../components/ConnectToXeroButton';
import ConnectToSageButton from '../components/ConnectToSageButton';
import '../css/branding.css';
import PageHeader from '../components/PageHeader';
import ErrorDialog from './ErrorDialog';
import LoadingDialog from './LoadingDialog';
import { API } from 'aws-amplify';
import config from '../config';
import TimeoutComponent from '../components/TimeoutComponent';

class ConnectAccountingSystem extends TimeoutComponent {
  constructor(props) {
    super(props);

    this.state = { url: '' };
  }

  async onClick_VideoHelp(event) {
    window.open(
      process.env.PUBLIC_URL +
        '/video.html?video_url=/video/HowToSetup-v1.1.mp4&video_title=Connect Accounting System&video_caption_en=/video/HowToSetup.en.vtt&video_poster=/video/HowToSetupPoster.png&video_width=1024&video_height=672',
      '_blank'
    );
  }

  getQuickbooksInfo() {
    console.log('API getOAuthURL');
    return API.get('cloudautocheckissues', '/getOAuthURL', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
      },
    });
  }

  async componentDidMount() {
    this.refs.loadingDialog.show(true);
    try {
      var qbInfo = await this.getQuickbooksInfo();
      if (qbInfo) {
        this.setState({ url: qbInfo.url });
      }
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      this.refs.loadingDialog.show(false);
    }
  }

  async onClick_CancelButton(event) {
    //    if (this.props.location.state.from === "upcomingdeliveries")
    //    {
    this.props.history.push('/upcomingdeliveries');
    //    }
  }

  render() {
    const cardStyle = {
      border: 'solid 1px',
      marginTop: '25px',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '300px',
      height: '250px',
    };

    const colStyle = {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    };

    return (
      <div className='ConnectAccountingSystem'>
        <div style={{ width: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <PageHeader
            title='Connect Accounting System'
            info='Select your online accounting system below and follow the prompts.'
          />
          <Button
            bsStyle='default'
            bsSize='small'
            onClick={() => {
              this.onClick_VideoHelp();
            }}
          >
            Video Help
            <img
              style={{ marginLeft: '5px' }}
              src={process.env.PUBLIC_URL + '/ic_play_arrow_24px.svg'}
              className='button-icon'
              alt='...'
            />
          </Button>
        </div>

        <Grid style={{ marginTop: '50px', width: '100%' }}>
          <Row>
            <Col style={colStyle} md={4}>
              <div style={cardStyle} className='widget'>
                <img
                  style={{ height: '100px' }}
                  src={process.env.PUBLIC_URL + '/qb-logo-stacked.svg'}
                  className='card-img-top'
                  alt='...'
                />
                <div style={{ marginTop: '40px', cursor: 'pointer' }} className='card-body'>
                  <ConnectToQuickbooksButton qbURL={this.state.url} />
                </div>
              </div>
            </Col>

            <Col style={colStyle} md={4}>
              <div style={cardStyle} className='widget'>
                <img
                  style={{ height: '100px' }}
                  src={process.env.PUBLIC_URL + '/logo_sage.png'}
                  className='card-img-top'
                  alt='...'
                />
                <div className='card-body'>
                  <ConnectToSageButton />
                </div>
              </div>
            </Col>
{/*
            <Col style={colStyle} md={4}>
              <div style={cardStyle} className='widget'>
                <img
                  style={{ height: '100px' }}
                  src={process.env.PUBLIC_URL + '/logo_xero.png'}
                  className='card-img-top'
                  alt='...'
                />
                <div className='card-body'>
                  <ConnectToXeroButton />
                </div>
              </div>
            </Col>
*/}            
          </Row>
        </Grid>
        <Button
          style={{ marginTop: '50px' }}
          onClick={() => {
            this.onClick_CancelButton();
          }}
          alt='...'
        >
          Cancel
        </Button>
        <p style={{ marginTop: '50px', fontSize: 'x-small' }}>
          QuickBooks is a registered trademark and service mark of Intuit Inc. in the United States
          and other countries.
        </p>
        <p style={{ fontSize: 'x-small' }}>Sage Business Cloud is a trademark of Sage Group plc.</p>
        {/*
        {
          this.props.location.state.from === "upcomingdeliveries" &&
          <Button style={{marginTop:'20px',marginRight:'15px'}} onClick={() => {this.onClick_CancelButton()}} alt="...">Cancel</Button>
        }
*/}
        <LoadingDialog ref='loadingDialog' />
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
}

export default ConnectAccountingSystem;

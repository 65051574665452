import { API } from 'aws-amplify';
import { FaultException } from 'igniteui-react-core';
import React from 'react';
import {
  Button,
  Checkbox,
  ControlLabel,
  FormControl,
  FormGroup,
  InputGroup,
  Radio,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import PageHeader from '../components/PageHeader';
import TimeoutComponent from '../components/TimeoutComponent';
import TimeZoneField from '../components/TimeZoneField';
import config from '../config';
import '../css/branding.css';
import ConfirmDialog from './ConfirmDialog'; // Import
import ErrorDialog from './ErrorDialog';
import LoadingDialog from './LoadingDialog';
import './RealmSettings.css';
import VerifyEmailDialog from './VerifyEmailDialog';
import VerifyMobileDialog from './VerifyMobileDialog';

const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

/*
const validateInput = (value) => {
  let error = '';

  if (!value) error = 'Required!';
  else if (value.length !== 14) error = 'Invalid phone format. ex: (555) 555-5555';

  return error;
};
*/

class RealmSettings extends TimeoutComponent {
  constructor(props) {
    super(props);

    this.settings = {};

    this.state = {
      identifier: '',
      timezone: '',
      notification_method: 'mobile',
      mobile_number_server: '',
      mobile_number: '',
      mobile_number_verified: false,
      email_server: '',
      email: '',
      email_verified: false,
      time_str: '00:00',
      time_numSecs: 0,
      //      phone: '',
      //      error: '',
    };

    this.mobile_info_text = "When you select Verify, the system will send a Verification Code via text to the number provided.";
    this.email_info_text = "When you select Verify, the system will send a Verification Code to the email address provided.";

    // EVENT HANDLERS
/*
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
*/
    // onChange
    this.handleChange_identifier = this.handleChange_identifier.bind(this);
    this.handleChange_time = this.handleChange_time.bind(this);
    this.handleChange_timeZone = this.handleChange_timeZone.bind(this);
    this.handleChange_notificationMethod = this.handleChange_notificationMethod.bind(this);
    this.handleChange_mobileNumber = this.handleChange_mobileNumber.bind(this);
    this.handleChange_email = this.handleChange_email.bind(this);

    // onClick
    this.handleClick_verifyMobile = this.handleClick_verifyMobile.bind(this);
    this.handleClick_verifyEmail = this.handleClick_verifyEmail.bind(this);

    this.handleVerifyMobileResult = this.handleVerifyMobileResult.bind(this);
    this.handleVerifyEmailResult = this.handleVerifyEmailResult.bind(this);

    this.displayError = this.displayError.bind(this);

    this.handleSetUpAnotherAnswer = this.handleSetUpAnotherAnswer.bind(this);

    this.handleVerifyPhoneNumber = this.handleVerifyPhoneNumber.bind(this);
  }

  // EVENT HANDLERS

  handleChange_mobileNumber({ target: { value } }) {
    this.setState((prevState) => ({
      mobile_number: normalizeInput(value, prevState.mobile_number),
    }));

    if (this.getServerFormattedPhoneNumber(value) === this.state.mobile_number_server) {
      this.setState({ mobile_number_verified: true });
    } else {
      this.setState({ mobile_number_verified: false });
    }
  }
  /*
  handleSubmit(e) {
    e.preventDefault();
    const error = validateInput(this.state.phone);

    this.setState({ error }, () => {
      if (!error) {
        setTimeout(() => {
          alert(JSON.stringify(this.state, null, 4));
        }, 300);
      }
    });
  }

  handleReset() {
    this.setState({ mobile_number: '', error: '' });
  }
*/
  // onChange
  // Hidden field to store file.sequence_num.
  handleChange_identifier(e) {
    this.setState({ identifier: e.target.value });
  }

  handleChange_time(timeInSeconds) {
    this.setState({
      time_numSecs: timeInSeconds,
      time_str: this.formatTime(timeInSeconds),
    });
  }

  handleChange_timeZone(e) {
    this.setState({ timezone: e.target.value });
  }

  handleChange_notificationMethod(e) {
    this.setState({
      notification_method: e.target.value,
      mobile_number: '',
      mobile_number_verified: false,
      //      mobile_number_enabled: e.target.value == "mobile",
      email: '',
      email_verified: false,
      //      email_enabled: e.target.value == "email"
    });
    if (e.target.value == 'mobile')
    {
      document.getElementById('mobile_info_text').style.visibility = "visible";
      document.getElementById('mobile_info_text').style.height = "";
      document.getElementById('mobile_info_text').innerText = this.mobile_info_text;
      document.getElementById('email_info_text').style.visibility = "collapse";
      document.getElementById('email_info_text').style.height = "0px";
      document.getElementById('email_info_text').innerText = "";
    }
    else if (e.target.value == 'email')
    {
      document.getElementById('mobile_info_text').style.visibility = "collapse";
      document.getElementById('mobile_info_text').style.height = "0px";
      document.getElementById('mobile_info_text').innerText = "";
      document.getElementById('email_info_text').style.visibility = "visible";
      document.getElementById('email_info_text').style.height = "";
      document.getElementById('email_info_text').innerText = this.email_info_text;
    }

  }

  /*  
  handleChange_mobileNumber(e) {
    this.setState({ mobile_number: e.target.value });

    if (this.getServerFormattedPhoneNumber(e.target.value) == this.state.mobile_number_server) {
      this.setState({ mobile_number_verified: true });
    } else {
      this.setState({ mobile_number_verified: false });
    }
  }
*/
  handleChange_email(e) {
    this.setState({ email: e.target.value });

    if (e.target.value === this.state.email_server) {
      this.setState({ email_verified: true });
    } else {
      this.setState({ email_verified: false });
    }
  }

  // onClick
  async handleClick_verifyMobile(e) {
    try {
      var success = false;
      var body = {
        body: {
          app_prefix: config.environment.APP_PREFIX,
          mobile_number: this.getFormattedPhoneNumber(),
        },
      };

      var apiResponse = await API.post('cloudautocheckissues', '/settings', body);

      if (apiResponse !== undefined) {
        if (apiResponse.status !== undefined) {
          if (apiResponse.status === 'success') {
            success = true;
            this.refs.verifyMobileDialog.displayDialog(this.handleVerifyMobileResult);
          }
        }
      }

      if (!success) {
        throw new Error('Verification code request failed.');
      }
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    }
  }

  async handleClick_verifyEmail() {
    try {
      if (!this.validateEmail()) {
        throw new Error('Email is invalid.');
      }

      var success = false;
      var body = {
        body: {
          app_prefix: config.environment.APP_PREFIX,
          email: this.state.email,
        },
      };

      var apiResponse = await API.post('cloudautocheckissues', '/settings', body);

      if (apiResponse !== undefined) {
        if (apiResponse.status !== undefined) {
          if (apiResponse.status === 'success') {
            success = true;
            this.refs.verifyEmailDialog.displayDialog(this.handleVerifyEmailResult);
          }
        }
      }

      if (!success) {
        throw new Error('Verification code request failed.');
      }
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    }

    //    this.refs.verifyDialog.displayDialog('test title', 'test text', this.handleVerifyEmailResult);
  }

  // Date Formatting -------------------------------------------------------------

  padWithZero(string) {
    return ('00' + string).slice(-2);
  }

  formatTime(timeInSeconds) {
    if (timeInSeconds < 0) {
      timeInSeconds = 0;
    }

    var hrs = Math.floor((timeInSeconds / 3600) % 24);
    var mins = Math.floor((timeInSeconds % 3600) / 60);

    return this.padWithZero(hrs) + ':' + this.padWithZero(mins);
  }

  getFormattedPhoneNumber() {
    // Validate Format
    const regex = /\(\d{3}\) \d{3}-\d{4}/gm;
    let isValid = regex.test(this.state.mobile_number);
    if (!isValid) {
      throw new Error('Phone number is invalid.');
    }

    return '+1' + this.state.mobile_number.replace(/\D/g, '');
  }

  validateEmail() {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email)
    ) {
      return true;
    } else {
      return false;
    }
  }

  //------------------------------------------------------------------------------

  handleVerifyMobileResult(resultStr) {
    var result = JSON.parse(resultStr);

    this.setState({ mobile_number_verified: result.success });

    if (result.success) {
      this.setState({ mobile_number_server: this.state.mobile_number });
    } else {
      if (result.error !== undefined) {
        this.refs.errorDialog.displayError('Error', result.error);
      }
    }
  }

  handleVerifyEmailResult(resultStr) {
    var result = JSON.parse(resultStr);

    this.setState({ email_verified: result.success });

    if (result.success) {
      this.setState({ email_server: this.state.email });
    } else {
      if (result.error !== undefined) {
        this.refs.errorDialog.displayError('Error', result.error);
      }
    }
  }

  async handleSetUpAnotherAnswer(answer) {
    try {
      if (answer) {
        var qbInfo = await getQuickbooksInfo();
        this.props.history.push({
          pathname: '/connectaccountingsystem',
          state: { url: qbInfo.url },
        });
      } else {
        this.props.history.push('/upcomingdeliveries');
      }
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
    }
  }

  async componentDidMount() {
    try {
      this.refs.loadingDialog.show(true);
      this.settings = await this.getSettings();
      if (!this.settings['timezone']) {
        this.settings['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      this.setState({ identifier: this.settings['identifier'] });
//      this.setState({ time_str: this.settings['time'] });
      this.setState({ timezone: this.settings['timezone'] });
      if (this.settings['mobile_number']) {
        this.setState({
          notification_method: 'mobile',
          mobile_number_server: this.settings['mobile_number'],
          mobile_number: this.getGuiFormattedPhoneNumber(this.settings['mobile_number']),
          mobile_number_verified: true,
        });

        document.getElementById('mobile_info_text').style.visibility = "visible";
        document.getElementById('mobile_info_text').style.height = "";
        document.getElementById('mobile_info_text').innerText = this.mobile_info_text;

        document.getElementById('email_info_text').style.visibility = "collapse";
        document.getElementById('email_info_text').style.height = "0px";
        document.getElementById('email_info_text').innerText = "";
      }
      if (this.settings['email']) {
        this.setState({
          notification_method: 'email',
          email_server: this.settings['email'],
          email: this.settings['email'],
          email_verified: true,
        });

        document.getElementById('mobile_info_text').style.visibility = "collapse";
        document.getElementById('mobile_info_text').style.height = "0px";
        document.getElementById('mobile_info_text').innerText = "";
        document.getElementById('email_info_text').style.visibility = "visible";
        document.getElementById('email_info_text').style.height = "";
        document.getElementById('email_info_text').innerText = this.email_info_text;
      }

      this.forceUpdate();
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      this.refs.loadingDialog.show(false);
    }
  }

  componentDidCatch(error, errorInfo) {
    this.displayError(errorInfo);
  }

  displayError(message) {
    this.refs.errorDialog.displayError('Error', message);
  }

  validateFields() {
    if (this.state.notification_method === 'mobile') {
      var num = this.refs.settingsForm.mobile.value.replace(/\D/g, '');
      if (num.length !== 10) {
        this.refs.errorDialog.displayError('Error', 'Please enter a 10 digit phone number.');
        return false;
      }
    } else {
      if (!this.validateEmail()) {
        this.refs.errorDialog.displayError('Error', 'Please enter valid email address.');
        return false;
      }
    }
    return true;
  }

  async handleClick_SaveSettings() {
    if (this.validateFields()) {
      this.refs.loadingDialog.setState({ show: true });
      var reply = await this.saveSettings();
      this.refs.loadingDialog.setState({ show: false });
      if (reply.status === 'success') {
        if (this.props.location.state.from === 'upcomingdeliveries') {
          this.props.history.push('/upcomingdeliveries');
        } else if (this.props.location.state.from === 'bankaccountsetup') {
          this.refs.confirmDialog.displayConfirmBox(
            'File Setup Complete',
            'Would you like to set up another file?',
            this.handleSetUpAnotherAnswer
          );
        }
      } else if (reply.error) {
        this.refs.errorDialog.displayError('Error', reply.error);
      }
    }
  }

  getServerFormattedPhoneNumber(guiFormattedPhoneNumber) {
    return '+1' + guiFormattedPhoneNumber.replace(/\D/g, '');
  }

  getGuiFormattedPhoneNumber(serverFormattedPhoneNumber) {
    var guiFormattedPhoneNumber = '';
    if (serverFormattedPhoneNumber.length === 12) {
      guiFormattedPhoneNumber =
        '(' +
        serverFormattedPhoneNumber.substring(2, 5) +
        ') ' +
        serverFormattedPhoneNumber.substring(5, 8) +
        '-' +
        serverFormattedPhoneNumber.substring(8);
    }

    return guiFormattedPhoneNumber;
  }

  getSettings() {
    return API.get('cloudautocheckissues', '/settings', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
        sequence_num: this.props.location.state.file.sequence_num,
      },
    });
  }

  saveSettings() {
    const form = this.refs.settingsForm;
    var body = {
      body: {
        app_prefix: config.environment.APP_PREFIX,
        sequence_num: form.sequence_num.value,
//        time: this.state.time_str,
        timezone: form.timezone.value,
//        monday: form.monday.checked,
//        tuesday: form.tuesday.checked,
//        wednesday: form.wednesday.checked,
//        thursday: form.thursday.checked,
//        friday: form.friday.checked,
//        saturday: false,
//        sunday: false,
        notify_file_sent: form.notify_file_sent.checked,
        notify_no_new_data: form.notify_no_new_data.checked,
      },
    };

    if (this.state.notification_method === 'mobile') {
      if (this.state.mobile_number && this.state.mobile_number.length > 0) {
        body.body['mobile_number'] = this.getServerFormattedPhoneNumber(this.state.mobile_number);
      }
    } else {
      if (this.state.email && this.state.email.length > 0) {
        body.body['email'] = this.state.email;
      }
    }

    return API.post('cloudautocheckissues', '/settings', body);
  }

  handleClick_CancelButton() {
    if (this.props.location.state.from === 'upcomingdeliveries') {
      this.props.history.push('/upcomingdeliveries');
    } else if (this.props.location.state.from === 'bankaccountsetup') {
      this.props.history.push('/bankaccountsetup');
    }
  }

  async handleVerifyPhoneNumber() {
    this.setState({ mobile_number_verified: true });
  }

  submitHandler(e) {
    e.preventDefault();
  }

  render() {
    return (
      <div className='Scheduler'>
        <PageHeader
          title='Settings'
          info='The system will retrieve new check data from your accounting system every hour from 6 AM to 9 PM Eastern. Enter how and when you would like to be notified.'
        />
        <div style={{ padding: '0px 20px 0px 20px' }} className='widget'>
          <form style={{ padding: '20px' }} ref='settingsForm' onSubmit={this.submitHandler}>
            <FormGroup style={{ maxHeight: '0px' }} controlId='sequence_num'>
              <FormControl
                type='hidden'
                defaultValue={this.props.location.state.file.sequence_num}
              />
            </FormGroup>
            <b>{this.props.location.state.file.company_name}</b>
            <hr style={{ marginTop: '5px' }} />

{/*            
            <img src={process.env.PUBLIC_URL + '/calendar.png'} alt='Shedule' />
            <b style={{ marginLeft: '13px' }}>Schedule</b>
            <hr style={{ marginTop: '5px' }} />
            <FormGroup style={{ marginLeft: '33px' }} controlId='time'>
              <ControlLabel>Time:</ControlLabel>
              <TimePicker
                start='07:00'
                end='23:00'
                format={12}
                step={60}
                style={{ marginLeft: '33px', width: '150px' }}
                onChange={this.handleChange_time}
                value={this.state.time_str}
              />
            </FormGroup>

            <FormGroup style={{ marginLeft: '33px', maxWidth: '450px' }} controlId='timezone'>
              <ControlLabel>Time Zone:</ControlLabel>
              <div style={{ marginLeft: '33px' }}>
                <TimeZoneField value={this.state.timezone} onChange={this.handleChange_timeZone} />
              </div>
            </FormGroup>

            <FormGroup style={{ marginLeft: '33px', marginBottom: '30px' }}>
              <ControlLabel>Days:</ControlLabel>
              <br />
              <div style={{ marginLeft: '33px' }}>
                <Checkbox name='monday' defaultChecked={this.settings['monday']} inline>
                  Monday
                </Checkbox>
                <Checkbox name='tuesday' defaultChecked={this.settings['tuesday']} inline>
                  Tuesday
                </Checkbox>
                <Checkbox name='wednesday' defaultChecked={this.settings['wednesday']} inline>
                  Wednesday
                </Checkbox>
                <Checkbox name='thursday' defaultChecked={this.settings['thursday']} inline>
                  Thursday
                </Checkbox>
                <Checkbox name='friday' defaultChecked={this.settings['friday']} inline>
                  Friday
                </Checkbox>
              </div>
            </FormGroup>
*/}

            <img src={process.env.PUBLIC_URL + '/bell.png'} alt='Notification' />
            <b style={{ marginLeft: '13px' }}>Notification</b>
            <hr style={{ marginTop: '5px' }} />
            <ControlLabel style={{ marginLeft: '33px' }}>Method:</ControlLabel>
            <div style={{ marginLeft: '66px' }}>
              <Radio
                name='radioGroup'
                checked={this.state.notification_method === 'mobile'}
                value='mobile'
                onChange={this.handleChange_notificationMethod}
              >
                Text: (Message and data rates may apply.)
              </Radio>
              <FormGroup
                validationState={this.state.mobile_number_verified ? 'success' : null}
                style={{ maxWidth: '450px', marginLeft: '20px' }}
                controlId='mobile'
              >
                <InputGroup style={{ width: '100%' }}>
                  <FormControl
                    disabled={this.state.notification_method === 'email'}
                    type='text'
                    ref='phoneNumber'
                    name='phone'
                    placeholder='Mobile # (111) 111-1111'
                    value={this.state.mobile_number}
                    onChange={this.handleChange_mobileNumber}
                  />
                  <InputGroup.Button>
                    <Button
                      disabled={
                        this.state.notification_method === 'email' ||
                        this.state.mobile_number_verified
                      }
                      onClick={this.handleClick_verifyMobile}
                    >
                      Verify
                    </Button>
                  </InputGroup.Button>
                </InputGroup>
                <ControlLabel>{this.state.mobile_number_verified ? 'VERIFIED' : ''}</ControlLabel>
                <br />
                <p id='mobile_info_text' visibility='hidden' style={{height:'0px'}}></p>
              </FormGroup>
              <Radio
                name='radioGroup'
                checked={this.state.notification_method === 'email'}
                value='email'
                onChange={this.handleChange_notificationMethod}
              >
                Email:
              </Radio>

              <FormGroup
                validationState={this.state.email_verified ? 'success' : null}
                style={{ maxWidth: '450px', marginLeft: '20px' }}
                controlId='email'
              >
                <InputGroup style={{ width: '100%' }}>
                  <FormControl
                    disabled={this.state.notification_method === 'mobile'}
                    type='text'
                    ref='email'
                    value={this.state.email}
                    onChange={this.handleChange_email}
                  />
                  <InputGroup.Button>
                    <Button
                      disabled={
                        this.state.notification_method === 'mobile' || this.state.email_verified
                      }
                      onClick={this.handleClick_verifyEmail}
                    >
                      Verify
                    </Button>
                  </InputGroup.Button>
                </InputGroup>
                {/*
              <EmailField
                ref='email_field_ref'
                disabled={this.state.notification_method != 'email'}
                defaultValue={this.state.email}
                onChange={this.handleChange_email}
              />
*/}
                <ControlLabel>{this.state.email_verified ? 'VERIFIED' : ''}</ControlLabel>
                <br />
                <p id='email_info_text' visibility='hidden' style={{height:'0px'}}></p>
              </FormGroup>
            </div>
            <FormGroup style={{ marginLeft: '33px', marginBottom: '30px' }}>
              <ControlLabel>Alert:</ControlLabel>
              <br />
              <div style={{ marginLeft: '33px' }}>
                <Checkbox
                  name='notify_file_sent'
                  defaultChecked={this.settings['notify_file_sent']}
                  inline
                >
                  If Check File Sent
                </Checkbox>
                <Checkbox
                  name='notify_no_new_data'
                  defaultChecked={this.settings['notify_no_new_data']}
                  inline
                >
                  If No New Check Data
                </Checkbox>
                <p style={{ marginTop: '15px' }}>Alerts will always be sent if an error occurs.</p>
              </div>
            </FormGroup>

            <FormGroup style={{ marginLeft: '33px', maxWidth: '450px' }} controlId='timezone'>
              <ControlLabel>Time Zone Preference:</ControlLabel>
              <div style={{ marginLeft: '33px' }}>
                <TimeZoneField value={this.state.timezone} onChange={this.handleChange_timeZone} />
              </div>
            </FormGroup>

            <p style={{ marginLeft: '33px', marginTop: '40px' }}>
              <a>Terms &amp; Conditions</a>&nbsp;|&nbsp;<a>Privacy Policy</a>
            </p>

          </form>
        </div>
        {this.props.location.state.from === 'upcomingdeliveries' && (
          <Button
            style={{ marginTop: '20px', marginRight: '15px' }}
            onClick={() => {
              this.handleClick_CancelButton();
            }}
            alt='...'
          >
            Cancel
          </Button>
        )}
        <Button
          style={{ marginTop: '20px' }}
          disabled={!this.state.mobile_number_verified && !this.state.email_verified}
          onClick={() => {
            this.handleClick_SaveSettings();
          }}
          alt='...'
        >
          Submit
        </Button>

        <VerifyEmailDialog ref='verifyEmailDialog' />
        <VerifyMobileDialog ref='verifyMobileDialog' />
        <LoadingDialog ref='loadingDialog' />
        <ConfirmDialog ref='confirmDialog' />
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
}

function getQuickbooksInfo() {
  return API.get('cloudautocheckissues', '/getOAuthURL', {
    queryStringParameters: {
      app_prefix: config.environment.APP_PREFIX,
    },
  });
}

export default RealmSettings;
